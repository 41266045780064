.additional-wage-filter {
  display: flex;
  gap: 4px;
  justify-content: flex-end;

  .ant-form-item {
    flex: 1;
  }

  .ant-form-item-label {
    margin-right: 11px;
  }

  .ant-form-item-control {
    margin-left: 22px;
  }
}
