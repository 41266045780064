.sub-header-ticket {
  background-color: var(--sub-header-background-color);
  padding-inline: 0 10px;
  margin-right: 10px;
  height: var(--sub-header-height);
  border-bottom: var(--divider-size) solid var(--divider-color);
  .sub-header-row {
    height: 100%;
    .panel-navigation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      // border-right: var(--divider-size) solid var(--divider-color);
      .menu-button {
        display: flex;
        align-items: center;
        height: 18px;
        cursor: pointer;
        img {
          width: 18px;
        }
        .menu-button-back {
          margin-right: 10px;
        }
        .menu-button-open {
          width: 22px;
        }
      }
      .breadcrumb {
        font-size: var(--font-size-medium);
      }
    }
    .panel-action {
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 16px !important;
      padding-right: 16px !important;
      .icon-list {
        display: flex;
        align-items: center;
        gap: 0 20px;
      }
    }
    .panel-search {
      .sub-header-search {
        width: 400px;
        background: var(--color-gradient-dark-50);
      }
    }
  }

  // Override css
  .ant-divider-vertical {
    height: 1.2rem;
  }
}
