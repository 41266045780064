.ticket-category {
  margin-top: 18px;

  .ticket-category-list {
    max-height: 50vh;
    overflow-y: hidden;
    scrollbar-gutter: stable;

    &:hover {
      overflow-y: auto;
    }
  }

  .ticket-category-item {
    border-block-end: 1px solid var(--divider-color) !important;
    cursor: pointer;
    padding-inline: 8px !important;

    &:hover {
      background: var(--color-gradient-light);
      cursor: pointer;
    }
  }
}
