.ticket-category-group {
  margin-top: 18px;

  .ticket-category-group-list {
    max-height: 50vh;
    overflow-y: hidden;
    scrollbar-gutter: stable;

    &:hover {
      overflow-y: auto;
    }
  }

  .ticket-category-group-item {
    padding: 14px;
    margin: 10px 2px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-block-end: none !important;
    cursor: pointer;
    padding-inline: 8px !important;

    &:hover {
      background: var(--color-gradient-light);
      cursor: pointer;
    }
  }
}
