:root {
  --header-height: 50px;
  --header-logo-width: 40px;
  --header-background-color: linear-gradient(
    90deg,
    rgb(3, 108, 182) 0%,
    rgba(47, 141, 207, 1) 55%,
    rgb(74, 148, 197) 100%
  );

  --sub-header-background-color: #fff;
  --sub-header-height: 63px;

  --footer-height: 26px;
  --footer-background-color: #d9d9d9;

  --content-padding: 40px;

  --divider-color: #f1f1f1;
  --divider-color-dark: #474748;
  --divider-color-gray: #474748b6;
  --divider-size: 2px;

  --input-line-height: 1.5rem;
  --input-padding-top: 0.375rem;
  --input-padding-bottom: 0.375rem;
  --input-border-radius: 0.375rem;

  --body-height: calc(100vh - 6px - var(--header-height) - var(--sub-header-height) - var(--footer-height));
  --body--no-sub-height: calc(100vh - 6px - var(--header-height) - var(--footer-height));
  --body-topic-height: calc(100vh - var(--header-height) - var(--footer-height));

  --font-size-small: 11px;
  --font-size-medium: 12px;
  --font-size-large: 13px;

  --font-weight-light: 400;
  --font-weight-normal: 500;
  --font-weight-bold: 700;

  --font-family: 'Inter', sans-serif;

  --color-disable: #f5f5f5;
  --color-primary: #036cb6;
  --color-light: #e6f4ff;
  --color-dark: #2a6f97;
  --color-button: #fff;
  --color-gradient-dark: linear-gradient(90deg, #007ff5 0%, rgb(213 96 112) 100%);
  --color-gradient-dark-50: linear-gradient(90deg, rgba(0, 127, 245, 0.5) 0%, rgba(214, 97, 113, 0.5) 100%);
  --color-gradient-normal: linear-gradient(90deg, rgb(230 244 255) 0%, rgb(247 240 244) 100%);
  --color-gradient-light: linear-gradient(90deg, rgb(230 244 255 / 50%) 0%, rgb(247 240 244 / 52%) 100%);
  --color-link: #1677ff;
  --color-link-hover: #69b1ff;
  --background-color-app: #fff;
  --background-color-primary: #036cb6;

  --radius-normal: 4px;

  // Status color
  --color-new: #0079bf;
  --color-approved-request: #3ba755;
  --color-accept-request: #2ca02c;
  --color-processing: #ffab4a;
  --color-handle-completed: #239b56;
  --color-finished: #1e8449;
  --color-rejected: #f55951;
  --color-canceled: #95a5a6;

  // Action color
  --color-action-approve: #3ba755;
  --color-action-accept: #2ca02c;
  --color-action-reject: #e74c3c;
  --color-action-cancel: #e74c3c;
  --color-action-edit: #1672ec;
  --color-action-delete: #ed972b;
  // --background-color-primary: #014f86;

  // Margin
  --margin-top: 16px;
  --margin-bottom: 16px;
  --margin-right: 16px;
  --margin-left: 16px;

  // Box shadow
  --bshd-light: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  --bshd-normal: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.App {
  text-align: center;
  font-weight: lighter;
}
* {
  font-family: var(--font-family) !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-container {
  &.home {
    &.mt {
      margin-top: 38px;
    }
  }

  &.mt {
    margin-top: calc(38px + var(--header-height));
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  // Animation khi unmount component
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

body::-webkit-scrollbar {
  width: 0px;
}

.manpower-supply-view .ant-space-item {
  flex: 1;
}

.quill.editorMesssageNotConfirm .ql-container {
  height: 150px;
}

/*Start: Css employee bank */
.ant-table-tbody tr.ant-table-row.default {
  background-color: rgb(3, 110, 183);
  .ant-table-cell {
    color: white;
  }
  .ant-btn-link {
    font-weight: 600;
    color: white;
  }
}

.ant-table-wrapper .ant-table-tbody > tr.ant-table-row.default:hover > td {
  background-color: rgb(3, 110, 183);
}

/*End: Css employee bank */

.row-warning {
  background-color: #fdd36a;
}

.ant-table-wrapper .ant-table-tbody > tr.ant-table-row.row-warning:hover > td {
  background-color: #fdd36a;
}

/*Start: Css fixed row of table hr ticket */
table tfoot.ant-table-summary > tr {
  position: sticky;
  bottom: -1px;
  background-color: inherit;
  width: 100%;
  background-color: #fafafa;
}

/*End: Css fixed row of table hr ticket */

.rounded-left-0 input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-left-0 .ant-input-group-addon {
  padding-left: 6px;
  padding-right: 6px;
}

:where(.css-dev-only-do-not-override-ph9edi).ant-picker-dropdown .ant-picker-time-panel-column::after {
  height: 0px;
}

.ant-picker-calendar.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  text-align: center;
  font-weight: 500;
}
.ant-picker-calendar.ant-picker-calendar-full .ant-picker-calendar-date-value {
  text-align: left;
}

.ant-picker-panel .ant-picker-content .ant-picker-cell:has(.holiday) .ant-picker-cell-inner {
  /* background-color: #ffe15d !important; */
  border-color: #1677ff;

  background: linear-gradient(90deg, rgb(0, 114, 245), rgb(255, 0, 34) 100%);
  background-clip: text;
  // -webkit-text-fill-color: transparent;
  // font-weight: 600;
  // font-size: 13px;
}

.ant-picker-calendar .ant-picker-cell-disabled {
  pointer-events: initial;
}

.card-feature {
  position: relative;
}

.card-feature::before {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: currentColor;
  opacity: 0.1;
  border-radius: inherit;
}

.ant-badge.ant-badge-status .ant-badge-status-text {
  font-size: 15px;
}

.filter-department-select > .ant-select-selector {
  border-start-start-radius: 4px !important;
  border-end-start-radius: 4px !important;
}

.filter-category-group-select > .ant-select-selector {
  border-start-start-radius: 4px !important;
  border-end-start-radius: 4px !important;
}

.ant-table.ant-table-middle.ant-table-bordered {
  margin-inline: 0px !important;
  margin-block: 0px !important;
}

.ticket-table .ant-table-content::-webkit-scrollbar {
  height: px;
}
.ticket-table .ant-table-content::-webkit-scrollbar-track {
  background-color: #fafafa;
}
.ticket-table .ant-table-content::-webkit-scrollbar-thumb {
  background: #c1c1c17a;
}

.form-label {
  inset-inline-start: 0 !important;
  margin-top: 0 !important;
}

.form-title > textarea,
.form-label > textarea {
  box-shadow: none !important;
  resize: none;
  height: 31.6px;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.form-item-drag {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 25px;
  cursor: all-scroll;
}

.form-item-drag::before {
  opacity: 0;
  visibility: hidden;

  content: ':::';
  top: -3px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 20px;
  letter-spacing: 1.5px;
  font-size: 18px;
  font-weight: 700;
  color: rgb(182, 183, 184);
  transition: transform 0.2s linear;
}

.form-item {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.form-item:hover .form-item-drag::before {
  opacity: 1;
  visibility: visible;
}

// Breadcrumb
.breadcrumb {
  font-size: var(--font-size-medium);
}

// Scrollbar
/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fafafa;
}

/* Handle */
::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: #dfdfdf;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #abaaaa;
}

// Layout
.ant-layout {
  background: transparent !important;
}

// Form.Item in Table

.ant-table {
  .ant-form-item {
    margin-bottom: 0px;
  }
  scrollbar-color: unset !important;
}
// Divider
.divider-mt0 {
  margin-top: 0 !important;
}

// SPINNER
.ant-spin-dot.ant-spin-dot-spin {
  transition: ease-in-out 0.5s !important;
  font-size: 20px !important;
  .ant-spin-dot-item {
    width: 9px !important;
    height: 9px !important;
  }
}

.disabled-link {
  pointer-events: none;
}

@keyframes fall {
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
    transform: translateY(100vh);
  }
}

@keyframes diagonal-fall {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
    transform: translate(10vw, 100vh);
  }
}

// Table antd
.ant-table {
  thead {
    background: var(--color-gradient-normal);
  }
  .ant-table-thead > tr > td,
  .ant-table-thead > tr > th {
    background: unset !important;
  }

  .react-resizable {
    position: relative;
    background-clip: padding-box;
  }

  .react-resizable-handle {
    position: absolute;
    right: -5px;
    bottom: 0;
    z-index: 1;
    width: 10px;
    height: 100%;
    cursor: col-resize;
  }
}
// Menu antd
.ant-dropdown {
  .ant-dropdown-menu-item-divider {
    background-color: var(--divider-color) !important;
  }
}

// Modal antd
.ant-modal-title {
  background: var(--color-gradient-normal);
  width: -moz-fit-content;
  width: fit-content;
  font-size: 14px !important;
  padding: 4px 8px;
  border-radius: 0 8px 8px 0;
  margin-left: -24px !important;
  padding-left: 24px;
}

// * required

.ant-space-item {
  .ant-typography-danger {
    display: inline-block !important;
    margin-inline-end: 4px !important;
    color: #ff4d4f !important;
    font-size: 14px !important;
    font-family: SimSun, sans-serif !important;
    line-height: 1 !important;
  }
}
