@keyframes slideLeft {
  0% {
    transform: translateX(100%);
    opacity: 0.1;
    visibility: hidden;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(-100%);
    opacity: 0.1;
    visibility: hidden;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }
}

.slide-show {
  &.to-left {
    animation: slideLeft 0.2s ease-in-out;
  }
  &.to-right {
    animation: slideRight 0.2s ease-in-out;
  }
}
